<template>
    <div class="page-game-player-summary index flex-center">
        <!-- <div class="player-sum-item scale-button flex-center" v-for="v in gameList" :key="v.value" :class="v.value" @click="gotoMenu(v)">
            <span class="text-large-d bold">{{v.name}}</span>
        </div> -->
        <div class="sum-item head">
            <pageHead></pageHead>
        </div>
        <div class="sum-item fun flex">
            <div class="func-item" v-for="v in gameList" :key="v.value" :class="[v.value, {'disabled-img': v.disabled, 'scale-button': !v.disabled}]" @click="gotoMenu(v)">
                <div class="ele-item body"></div>
            </div>
        </div>
        <div class="sum-item extends">
            <div class="ext-item scale-button" v-for="v in extendFun" :key="v.value" :class="[v.value, {'disabled-img': v.disabled, 'scale-button': !v.disabled}]" @click="extendFunClick(v)">
                <div class="ele-item body"></div>
            </div>
        </div>
        <rankList ref="rankList" style="z-index: 301;"></rankList>
    </div>
</template>
<script>
    import pageHead from './components/summaryHead.vue'
    import rankList from './components/rankList.vue'

    export default {
        name: '',
        props: {
        },
        data() {
            return {
                gameList: [
                    {name: '快速匹配', value: 'taste', disabled: false, pathName: 'player-menu-taste'},
                    {name: '好友约战', value: 'invite', pathName: 'player-menu-invite'},
                ],
                extendFun: [
                    {name: '排行榜', value: 'rank', fun: 'rankFun'},
                    {name: '历史记录', value: 'record',fun: 'recordFun', pathName: 'player-game-room-history'},
                ]
            };
        },
        methods: {
            gotoMenu(val){
                let {pathName: name} = val || {}
                if(!name) return
                this._routeHand.goto({name})
            },
            extendFunClick(val){
                let {fun} = val || {}
                if(!fun) return
                this[fun](val)
            },
            rankFun(){
                this.$refs['rankList'].open()
            },
            recordFun(val){
                let {pathName: name} = val || {}
                if(!name) return
                this._routeHand.goto({name})
            },
            roomCheck(){
                this._eventBus.$emit('gameLinkTakeFun', {
                    fun: 'gameroomStateCheck',
                })
            },
        },
        created() {
            this.roomCheck()
        },
        components: {
            pageHead,
            rankList,
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .page-game-player-summary.index{
        @skewing: 81px;
        position: absolute;
        top: 0px;
        left: @skewing;
        // left: 0px;
        width: 100%;
        height: 100%;
        .player-sum-item{
            width: 150px;
            height: 150px;
        }
        .player-sum-item.taste{
            background: #6F83DE;
        }
        .player-sum-item.invite{
            background: #F9BD6B;
        }
        .player-sum-item+ .player-sum-item{
            margin-left: 20px;
        }


        // UI
        .sum-item{
            position: absolute;
        }
        .sum-item.head{
            top: 58px;
            // left: 36px - @skewing;
            left: -9px;
        }
        .sum-item.fun{
            top: 135px;
            // left: 25px - @skewing;
            left: -14px;
            
            .func-item{
                position: relative;
                .ele-item{
                    position: absolute;
                }
                .ele-item.body{
                    z-index: 2;
                }
                .ele-item.shadow{
                    z-index: 1;
                }
            }
            .taste{
                width: 256px;
                height: 186px;
                .body{
                    width: 256px;
                    height: 186px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p4.jpg'));
                }
                // .shadow{
                //     width: 249px;
                //     height: 195px;
                //     .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p4_shadow.png'));
                // }
            }
            .invite{
                
                // margin-left: -6px;
                width: 249px;
                height: 194px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p5_shadow.png'));
                .body{
                    margin-top: -16px;
                    margin-left: 1px;
                    width: 241px;
                    height: 201px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p5.png'));
                }
                .shadow{
                    top: 15px;
                    width: 249px;
                    height: 194px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p5_shadow.png'));
                }
            }
        }
        
        .sum-item.extends{
            top: 58px;
            left: 579px - @skewing;
            .ext-item{
                position: relative;
                .ele-item{
                    position: absolute;
                }
                .ele-item.body{
                    z-index: 2;
                }
                .ele-item.shadow{
                    z-index: 1;
                }
            }
            .rank{
                width: 79px;
                height: 172px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p6_shadow.png'));
                .body{
                    left: 1px;
                    width: 71px;
                    height: 163px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p6.png'));
                }
            }
            .record{
                margin-top: 8px;
                margin-left: -11px;
                width: 71px;
                height: 92px;
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p7_shadow.png'));
                .body{
                    left: 1px;
                    width: 63px;
                    height: 83px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p7.png'));
                }
            }
        }
    }
</style>