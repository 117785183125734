<!-- -->
<template>
    <div class='page-game-rank-list flex-center' v-if="show">
        <div class="bcg">
            <div class="mark"></div>
            <div class="p9">
                <div class="off-btn" @click="close()"></div>
                <div class="rank-box">
                    <div class="rank-thead">
                        <div class="title a">
                            <span class="text-mini-a bold">{{'排名'}}</span>
                        </div>
                        <div class="title b">
                            <span class="text-mini-a bold">{{"棋手"}}</span>
                        </div>
                        <div class="title c">
                            <span class="text-mini-a bold">{{'品级'}}</span>
                        </div>
                        <div class="title d">
                            <span class="text-mini-a bold">{{'星级'}}</span>
                        </div>
                    </div>
                    <div class="items-empty flex-center" v-if="rankListLength==0">
                        <span class="title a bold text-normal-aa">{{"暂无排行"}}</span>
                    </div>
                    <div class="rank-tbody" v-else>
                        <div class="items mini-scrollbar" 
                        ref="commentContent"
                        >
                            <div ref="commentBox">
                                <div class="item" :class="{'current-user': v.userCode == usercode,'last-bottom': !isCurrentUserInfo}" v-for="(v,i) in rankListCul" :key="i">
                                    <div class="title a bold flex-center">
                                        <div :class="{'rank': i<3}" class="flex-center">
                                            <span class="title bold text-normal-aa flex-center">{{i+1}}</span>
                                        </div>
                                    </div>
                                    <div class="title b bold">
                                        <span class="title bold text-normal-aa">{{v.nickname}}</span>
                                    </div>
                                    <div class="title c bold">
                                        <span class="title bold text-normal-aa">{{v.level}}</span>
                                    </div>
                                    <div class="title d flex-center">
                                        <div class="star"></div>
                                        <span class="title bold text-normal-aa x">{{'X'}}</span>
                                        <span class="title bold text-normal-aa">{{v.goalDifference}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <loading v-show="isLoading" :loading.sync="loading" :color="`#977C68`"></loading> -->
                        </div>
                        <div class="item current-user fixed" v-if="!isCurrentUserInfo">
                            <div class="title a bold flex-center">
                                <div class="flex-center">
                                    <span class="title bold text-normal-aa flex-center">{{'未上榜'}}</span>
                                </div>
                            </div>
                            <div class="title b bold">
                                <span class="title bold text-normal-aa">{{gamezoneUserInfoCul.nickname}}</span>
                            </div>
                            <div class="title c bold">
                                <span class="title bold text-normal-aa">{{gamezoneUserInfoCul.level}}</span>
                            </div>
                            <div class="title d flex-center">
                                <div class="star"></div>
                                <span class="title bold text-normal-aa x">{{'X'}}</span>
                                <span class="title bold text-normal-aa">{{gamezoneUserInfoCul.goalDifference}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClass from '@/API/page/game'
import apiGame from '@/API/page/socialGame'
// import loading from '@/views/history/components/loading.vue'
    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new apiClass(),
                apiGame: new apiGame(),
                show: false,
                rankList: [],
                page: 0,
                size: 500,
                rankTotal: 100,
                loading: false,
                gamezoneUserInfo: {},
                domain: '0077'
            };
        },
        methods: {
            pageInit(){
                
                return Promise.all([
                    this.getRankList(),
                    this.getGamezoneUserInfo()
                ])
            },
            async open(){
                await this.pageInit()
                this.show = true
            },
            close(){
                this.show = false
            },
            async getGamezoneUserInfo(){
                let { usercode: userCode } = this
                let res = await this.apiGame.gamezoneUserInfo({userCode})
                if(!res) return
                this.gamezoneUserInfo = res
            },
            async getRankList(){
                let { page, size, domain } = this
                let res = await this.api.rankList({page, size, domain})
                if(!res) return
                this.rankList = res
            },
            async scrolLoad(){
                let {page,size,rankListLength:length,rankTotal:total,domain} = this
                if(this.loading == true) return
                if(!this.$refs['commentContent']) return
                let index = this.$refs['commentContent'].scrollTop
                let commentContent = this.$refs['commentContent'].offsetHeight
                let commentBox = this.$refs['commentBox'].offsetHeight
                if(commentBox - index + 31 > commentContent) return
                this.loading = true
                if(length==total){
                    this.loading = false
                    return
                }
                page++
                let res = await this.api.rankList({page, size, domain, noLoad: true})
                if(!res) return
                this.page = page
                // this.rankTotal = res.total
                this.rankList = this.rankList.concat(res)
                this.loading = false
            }
        },
        created() {
            // this.pageInit()
        },
        components: {
            // loading,
        },
        computed: {
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            gamezoneUserInfoCul(){
                let { gamezoneUserInfo: info } = this
                if(info.level > 2900) info.level = 2900
                info.level = this._common.getLevel(info.level).name || '暂无品级'
                return info
            },
            rankListCul(){
                let { rankList: list } = this
                list = this._common.deepCopy(list)
                for (const i in list) {
                    if(list[i].level > 2900) list[i].level = 2900
                    list[i].level = this._common.getLevel(list[i].level).name || '暂无品级'
                }
                return list
            },
            rankListLength(){
                let { rankList: list } = this
                list = this._common.deepCopy(list)
                return list.length
            },
            // isLoading(){
            //     let {rankListLength,size} = this
            //     let rd =false 
            //     if(rankListLength/size>=1) rd = true
            //     return rd
            // },
            isCurrentUserInfo(){
                let { rankList: list, usercode } = this
                list = this._common.deepCopy(list)
                let rd = false
                for (const i in list) {
                    if(list[i].userCode == usercode) {
                        rd = true
                        continue
                    }
                }
                return rd
            }
        },
        watch: {
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>
    .page-game-rank-list{
        width: 100%;
        padding-right: @app-padding-side;
        height: 100%;
        .bcg{
            z-index: 301;
            background: rgba(0, 0, 0, 0.65);
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            .mark{
                width: 100vw;
                height: 100vh; 
            }
            .p9{
                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p9.png'));
                width: 465px;
                height: 349px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 103px;
                margin: auto;
                transform: translateY(-10px);
                .off-btn{
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p10.png'));
                    width: 17px;
                    height: 17px;
                    position: absolute;
                    top: 89px;
                    right: 20px;
                }
                .rank-box{
                    width: 370px;
                    height: 260px;
                    position: absolute;
                    top: 117px;
                    left: 46px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    .title{
                        color: #A48974;
                    }
                    .rank-thead{
                        height: 23px;
                        display: flex;
                        justify-content: center;
                        padding-right: 8px;
                        .a{
                            flex: 1;
                        }
                        .b{
                            flex: 1.5;
                        }
                        .c{
                            flex: 1;
                        }
                        .d{
                            flex: 1.25;
                        }
                    }
                    .items-empty{
                        width: 370px;
                        height: 187px;
                        padding: 4px 0 0 1px;
                    }
                    .rank-tbody{
                        width: 370px;
                        height: 187px;
                        position: relative;
                        .items{
                            width: 370px;
                            height: 187px;
                            padding: 4px 0 0 1px;
                            border: 0px;
                            .last-bottom.item:last-child{
                                margin-bottom: 34px;
                            }
                        }
                        .item.current-user.fixed{
                            pointer-events: none;
                            position: absolute;
                            bottom: 0px;
                            width: 362px
                        }
                        .item.current-user{
                            .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p15.png'));
                            .title{
                                color: #975B36;
                            }
                        }
                        .item{
                            .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p11.png'));
                            display: flex;
                            align-items: center;
                            width: 361px;
                            height: 39px;
                            margin-bottom: -2px;
                            padding-bottom: 4px;
                            .rank{
                                .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p14.png'));
                                width: 21px;
                                height: 27px;
                                .title{
                                    width: 27px;
                                    height: 27px;
                                    color: #734325;
                                    transform: scale(0.6);
                                    position: relative;
                                    top: -3px;
                                }
                            }
                            .a{
                                flex: 1;
                            }
                            .b{
                                flex: 1.5;
                            }
                            .c{
                                flex: 1;
                            }
                            .d{
                                flex: 1.25;
                            }
                            .title{
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                color: #977C68;
                            }
                            .title.d{
                                justify-content: flex-start;
                                position: relative;
                                left: 10px;
                                .star{
                                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p13.png'));
                                    display: inline-block;
                                    width: 29px;
                                    height: 28px;
                                    position: relative;
                                    top: 1px;
                                }
                                .x{
                                    margin-right: 2px;
                                }
                            }
                        }
                        .item>.title{
                            height: 39px;
                            line-height: 39px;
                            span{
                            height: 39px;
                            line-height: 39px;
                            }
                        }
                    }
                }
            }
        }
        // 滚动条
        .mini-scrollbar::-webkit-scrollbar{
            width: 9px;
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar-x::-webkit-scrollbar{
            height: 4px;
        }
        .mini-scrollbar::-webkit-scrollbar-thumb, .mini-scrollbar-x::-webkit-scrollbar-thumb{
            border-radius: 10px;
            // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            // background: rgba(0,0,0,0.2);
            .background-img-max(url('@{assetsUrlV2_0}/task_center/d2_0.png'));
            // background: #FFFAE6;
            // background: url('@{assetsUrlV2_0}/avatar/c30.png') no-repeat;
            // background-position: center;
            
        }
        .mini-scrollbar::-webkit-scrollbar-track-piece{
            // background: red;
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar::-webkit-scrollbar-thumb::before{
            content: '';
            .background-img-max(url('@{assetsUrlV2_0}/task_center/d2_0.png'));
        }
        .mini-scrollbar::-webkit-scrollbar-track, .mini-scrollbar-x::-webkit-scrollbar-track{
            // -webkit-box-shadow: inset 0 0 5px #C7946A;
            border-radius: 10px;
            // background: rgba(178,123,85,0.1);
            // background: #B27B55;
            background-image: linear-gradient(to right, #CAB39E, #DBC6B4, #C9B19C);
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar{
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
</style>