<!-- -->
<template>
    <div class='page-game-player-rule flex-center mind' v-if="show">
        <div class="bcg">
            <div class="mark" @click="close()"></div>
            <div class="d1">
                <div class="off-btn" @click="close()"></div>
                <div class="stone-record-title flex-center">
                    <span class="rule-head-title bold text-purple-color">{{"升级规则"}}</span>
                </div>
                <div class="items mini-scrollbar" 
                ref="commentContent"
                >
                    <div ref="commentBox">
                        <div class="item" v-for="(v,i) in ruleList" :key="i">
                            <div class="con">
                                <span class="rule-title-size bold text-brown-color" :class="{'t': !v.clearIndent}">{{v.chapter}}</span>
                            </div>
                            <!-- <table class="content" cellspacing="0">
                                <div class="row">
                                    <span :class="{t:i==0||i==1,}" class="text-normal bold text-brown-color">{{v.chapter}}</span>
                                    <span class="text-normal bold text-brown-color r">{{v.level}}</span>
                                </div>
                            </table> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: '',
        props: {
        },
        data() {
            return {
                ruleList:[
                    {chapter: "初次进入人人对弈模块时，默认等级为“九品下”，一颗星星。此后每战胜一局，就获得一颗星星，当一个段位的星星数已经满星时，再次获胜将晋升下一个段位，段位初始为一颗星星。每失败一局，将减少一颗星星，当一个段位只剩一颗星星时，再次失败会下降一个段位，同时显示为满星状态。对弈过程中，如果连胜会触发额外的星星奖励：连续第 5 次胜利，该局可以获得两颗星星；连续第 10 次胜利，该局可以获得三颗星星；连续第 15 次胜利，该局可以获得四颗星星，以此类推…..当晋升至“一品上”的满级段位后，每次对弈获胜依然奖励星星，但段位不再上升。"},

                    {chapter: "星星增长规则", clearIndent: true},
                    {chapter: "1）三品及以下几种玩法都可以获得星星", clearIndent: true},
                    {chapter: "2）二品只有对弈可以获得星星", clearIndent: true},
                    {chapter: "3）一品只有19路对弈可以获得星星", clearIndent: true},

                    // 星星增长规则 - 1）三品及以下几种玩法都可以获得星星
                    // 2）二品只有对弈可以获得星星
                    // 3）一品只有19路对弈可以获得星星
                ],
                show: false,
            };
        },
        methods: {
            open(){
                this.show = true
            },
            close(){
                this.show = false
            },
        },
        created() {
        },
        components: {
        },
        computed: {
        },
        watch: {
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>

<style lang="less" scoped>
.page-game-player-rule {
    @text-purple-color: #554B93;
    @text-brown-color: #978179;

    .text-brown-color {
        color: @text-brown-color;
    }

    .text-purple-color {
        color: @text-purple-color;
    }

    .stone-record-size {
        font-size: 13px;
    }

    .min {
        transform: scale(0.9);
    }

    width: 100%;
    padding-right: @app-padding-side;
    height: 100%;

    .bcg {
        z-index: 301;
        background: rgba(0, 0, 0, 0.65);
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;

        .mark {
            width: 100vw;
            height: 100vh;
        }

        .d1 {
            .background-img-max(url('@{assetsUrlMind}/img/global/c1_2.png'));
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 418px;
            height: 273px;
            .off-btn {
                .background-img-max(url('@{assetsUrlV2_0}/task_center/d5.png'));
                position: absolute;
                top: 7px;
                right: -2px;
                width: 17px;
                height: 17px;
                cursor: pointer;
                z-index: 1;
            }
            .stone-record-title {
                position: absolute;
                top: 2px;
                width: 80px;
                height: 30px;
                left: 0;
                right: 0;
                margin: auto;
                .rule-head-title{
                    font-size: 13px;
                }
            }
            .items {
                position: absolute;
                top: 54px;
                left: 39px;
                width: 369px;
                height: 173px;
                .item {
                    padding: 0 34px 0 0;
                    .con {
                        width: 100%;
                        display: flex;
                        padding: 0 5px;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        padding: 0;
                        .rule-title-size{
                            font-size: 12px;
                            line-height: 18px;
                        }
                        .tip{
                            padding: 14px 0 6px 0;
                            font-size: 11px;
                        } 
                        
                        .t {
                            text-indent: 26px;
                        }

                        .l {
                            flex: 1.5;
                            text-align: left;
                        }

                        .c {
                            flex: 1.5;
                            text-align: center;
                        }

                        .r {
                            flex: 1;
                            text-align: right;
                        }
                    }
                    .content{
                        width: 100%;
                        border-color: #978179;
                        padding: 0 26px;
                    }
                    .row{
                        width: 100%;
                        display: flex;
                        span{
                            width: 50%;
                            text-align: center; 
                            height: 30px;
                            line-height: 30px;
                            border-top: 2px solid #978179;
                            border-left: 2px solid #978179;
                        }
                        span:nth-of-type(even){
                            border-right: 2px solid #978179;
                        }
                    }
                    .d3 {
                        .background-img-max(url('@{assetsUrlV2_0}/task_center/d3.png'));
                        width: 207px;
                        height: 1px;
                        margin-bottom: 7px;
                    }
                }
                .item:nth-of-type(even){
                    .row{
                        background: #FFFFFF;
                    }
                }
                .item:nth-of-type(odd){
                    .row{
                        background: #FBF3EE;
                    }
                }
                .item:nth-last-child(1){
                    .row:nth-last-child(1),.row:nth-last-child(2){
                        span{
                            border-bottom: 2px solid #978179;
                        }
                    }
                }
            }
            .items-empty {
                position: absolute;
                top: 36px;
                left: 25px;
                width: 235px;
                height: 219px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .earn-stone {
                // .background-img-max(url('@{assetsUrlV2_0}/task_center/d4_0.png'));
                width: 136px;
                height: 45px;
                position: absolute;
                bottom: 15px;
                left: 75px;

                .d4 {
                    // .background-img-max(url('@{assetsUrlV2_0}/task_center/d4.png'));
                    width: 131px;
                    height: 39px;
                    position: absolute;
                    top: 3px;
                    left: 3px;
                }
            }
        }
    }

    /deep/.com-confirm-button {
        width: 100%;
        height: 100%;
    }

    // 滚动条
    .mini-scrollbar::-webkit-scrollbar {
        width: 8px;
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }

    .mini-scrollbar-x::-webkit-scrollbar {
        height: 4px;
    }

    .mini-scrollbar::-webkit-scrollbar-thumb,
    .mini-scrollbar-x::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        // background: rgba(0,0,0,0.2);
        .background-img-max(url('@{assetsUrlV2_0}/task_center/d2_0.png'));
        // background: #FFFAE6;
        // background: url('@{assetsUrlV2_0}/avatar/c30.png') no-repeat;
        // background-position: center;

    }

    .mini-scrollbar::-webkit-scrollbar-track-piece {
        // background: red;
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }

    .mini-scrollbar::-webkit-scrollbar-thumb::before {
        content: '';
        .background-img-max(url('@{assetsUrlV2_0}/task_center/d2_0.png'));
    }

    .mini-scrollbar::-webkit-scrollbar-track,
    .mini-scrollbar-x::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 5px #C7946A;
        border-radius: 10px;
        // background: rgba(178,123,85,0.1);
        // background: #B27B55;
        background-image: linear-gradient(to right, #CBBDAF, #DCD1C1, #CABCAE);
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }

    .mini-scrollbar {
        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>