<!--  -->
<template>
    <div class='com-global-setting'>
        <confirm-pro ref="confirm">
            <div class="setting-content" slot="message">
                <div class="content-item" v-for="(v, i) in setListCul" :key="i" @click="changeSet(v)">
                    <div class="item-text flex-center">
                        <span class="text-normal-aa bold">{{v.text}}</span>
                    </div>
                    <div class="item-btn">
                        <sl-button :type="v.btnType">
                            <span class="text-normal-aa">{{v.btnText}}</span>
                        </sl-button>
                    </div>
                </div>
            </div>
        </confirm-pro>
    </div>
</template>

<script>
    import com from '@/components/setting.vue'
    export default {
        name: '',
        props: {},
        extends: com,
        data() {
            return {
                setList: [
                    {text: this.TEXT.components['background music'], state: 1, value: 'backgroundMusic'},
                    {text: this.TEXT.components['play voice'], state: 0, value: 'playVoice'},
                    {text: this.TEXT.components['play confirm'], value: 'confirmPlay'},
                ],
            };
        },
        methods: {
        },
        created() {
        },
        components: {
        },
        computed: {
        },
        watch: {},
        mounted() {
            // this.$refs.confirm.open({
            //     showClose: true,
            //     title: '设置',
            //     buttons: []
            // })
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
</style>