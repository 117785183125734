<template>
    <div class="com-social-game-summary-head">
        <div class="h-item body flex">
            <div class="body-item photo">
                <user-photo></user-photo>
            </div>
            <div class="body-item user-info">
                <div class="info-name">
                    <nickname class="bold"></nickname>
                </div>
                <div class="info-ex bold flex">
                    <div class="ex-line-label"></div>
                    <div class="ex-level flex">
                        <span class="text-small t">品级:</span>
                        <user-level class="flex-center"></user-level>
                    </div>
                    <div class="ex-goalDifference flex">
                        <span class="text-small t flex">星级:</span>
                        <goal-difference></goal-difference>
                    </div>
                </div>
            </div>
            <div class="body-item rule-box flex">
                <div class="rule-item rule scale-button " @click="openRule()">
                    <span class="text-normal bold">升级规则</span>
                </div>
                <div class="rule-item setting scale-button"  @click="openSetting()">
                    <span class="text-normal bold">设置</span>
                </div>
            </div>
        </div>
        <!-- <div class="h-item shadow"></div> -->
        <rule ref="rule"></rule>
        <setting ref="setting"></setting>
    </div>
</template>
<script>
    import rule from './rule.vue'
    import setting from './setting.vue'
    import apiClass from '@/API/page/socialGame'

    export default {
        name: '',
        props: {
        },
        data() {
            return {
                api: new apiClass(),
                appData: new this.appDataClass({key: 'app'})
            };
        },
        methods: {
            openRule(){
                this.$refs['rule'].open()
            },
            openSetting(){
                this.$refs['setting'].open()
            },
            async pageInit(){
                await this.api.userAccount()
                let nickname = this.appData.getItem('nickname')
                this._common.nicknameCheck(nickname)
                this.api.userLevel({noLoad: true, noError: true})
            }
        },
        created() {
            this.pageInit()
        },
        components: {
            rule,
            setting,
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .com-social-game-summary-head{
        @width: 511px;
        width: @width;
        height: 68px;
        .shadow{
            width: @width;
            height: 68px;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
            .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p1_shadow.png'));
        }
        .body{
            .max-size();
            .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p1.jpg'));
            padding-left: 76px;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 2;
            .photo{
                margin-top: 9px;
                width: 41px;
                height: 41px;
            }
            .user-info{
                margin-top: 10px;
                margin-left: 6px;
                .com-nickname{
                    width: 136px;
                    color: #68587E;
                    span[class^='text']{
                        font-size: 17px;
                    }
                }
                .info-ex{
                    // margin-top: 4px;
                    padding-top: 3px;
                    color: #8F798E;
                    border-top: 1.1px solid #8F798E;
                    position: relative;
                    .global-com-user-level{
                        span[class^='text']{
                            font-size: 12px;
                        }
                    }
                    .t{
                        margin-right: 2px;
                    }
                    .ex-line-label{
                        @s: 5px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        margin: auto;
                        transform: translateY(-70%);
                        width: @s;
                        height: @s;
                        border-radius: 100%;
                        background: #8F798E;
                    }
                    .ex-goalDifference{
                        margin-left: 17px;

                    }
                }
            }
            .body-item.rule-box{
                // margin-left: 116px;
                // margin-top: 9px;
                position: absolute;
                top: 10px;
                right: 16px;
                .rule{
                    width: 84px;
                    height: 41px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p3.png'));
                    padding-top: 15px;
                    padding-left: 10px;
                }
                .setting{
                    margin-left: 5px;
                    width: 60px;
                    height: 42px;
                    margin-top: -1px;
                    padding-top: 16px;
                    padding-left: 21px;
                    .background-img-max(url('@{assetsUrlV2_0}/game/socialGame/summary/p2.png'));
                }
            }
        }
    }
</style>